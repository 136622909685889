import axios from "axios";

const token = "bHDa4XaYYxOPPMMaxIttx8a8IA1q9Wy6YE8cmBMCdGWhHr/wGkvBWQ==";
const baseUrl = "https://oonpay.azurewebsites.net/api";

export const state = {
  desc: "",
  services_faq: "",

  businesspermtag: "",
  businesscountryid: null || 1,

  user: null,
  mainphonenumber: null,
  permtag: null,
  verify_admin_token: null,
  otp_mobile: "",
  selected_code: null,
  save_phone: null,
  countryId: null,
  town: null,
  health_hub_req_data: null,
  healthhub_create: "",
  loading: false,
  error: null,
  sub_category: [],
  category: [],
  paginated_data: [],
  errors: {},
};

export const actions = {
  updateDesc({ commit }, description) {
    commit("set_New_Desc", description);
  },
  updateServiceFAQ({ commit }, data) {
    commit("SET_SERVICE_FAQ", data);
  },




  BusinessPerMaTag({ commit }, tag) {
    commit("setBusPerMaTag", tag);
  },
  setCountry({ commit }, id) {
    commit("ID", id);
  },

  updatePhoneNumber({ commit }, data) {
    commit("setPhoneNumber", data);
  },
  updateMainPhoneNumber({ commit }, data) {
    commit("setmainPhoneNumber", data);
  },

  CheckPermTag({ commit }, data) {
    commit("setPermTag", data);
  },
  SetCountryCode({ commit }, data) {
    commit("setCountryCode", data);
  },

  SetMainPhone({ commit }, data) {
    commit("SAVE_PHONE", data);
  },

  CountryId({ commit }, data) {
    commit("SET_COUNTRYID", data);
  },

  verifyOTP({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseUrl}/general/otp/verify/${data}`, {
          headers: {
            webAuthKey: token,
          },
        })
        .then((response) => {
          commit("SET_VERIFY_OTP", response.data);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  VerifyAdminPhone({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseUrl}/general/otp/mobile/${data.phonenumber}/${data.code}`, {
          headers: {
            webAuthKey: token,
            increaseLimit: true
          },
        })
        .then((response) => {
          commit("VERIFY_ADMIN", response);
          console.log(response)
          resolve(response);
        })
        .catch((err) => {
          console.log("err", err.response.data)
          commit("SET_ERROR", err.response)
          reject(err)
        });
    });
  },


  GetRequiredData() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseUrl}/merchantaccounts/getrequireddataforsignuporedit`, {
          headers: {
            webAuthKey: token,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },

  CreateMerchantAccount({ commit }, formData) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseUrl}/merchantaccounts/create`, formData, {
          headers: {
            webAuthKey: token,
            adminPhoneNumberGUID: localStorage.getItem("adminPhoneNumberGUID"),
            handshakeGUID: localStorage.getItem("handshakeGUID")
          },
        })
        .then((response) => {
          if (response.status == 200) {
            commit("CREATE_MERCHANT", response);
          }
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },

  CheckPermTagAvialabiity({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseUrl}/merchantaccounts/permtagexist?${data.permtag}`, {
          headers: {
            webAuthKey: token,
          },
        })
        .then((response) => {
          commit("SET_PERM_TAG", response);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  GetHealthHubRequiredData({ commit }) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", true);
      axios
        .get(
          `${baseUrl}/healthhubpackages/merchant/getrequireddataforcreateoredit`,
          {
            headers: {
              webAuthKey: token,
              employeeGUID: localStorage.getItem("employeeGUID"),
            },
          }
        )
        .then(({ data }) => {
          commit("SET_HEALTH_HUB_REQ_DATA", data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => commit("SET_LOADING", false));
    });
  },
  healthhubMerchantCreate({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseUrl}/healthhubpackages/merchant/create`, payload, {
          headers: {
            webAuthKey: token,
            employeeGUID: localStorage.getItem("employeeGUID"),
          },
        })
        .then((response) => {
          commit("SET_Healthhub_Merchant_Create", response);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  UpdateHealthPackage({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${baseUrl}/healthhubpackages/merchant/edit`, payload, {
          headers: {
            webAuthKey: token,
            employeeGUID: localStorage.getItem("employeeGUID"),
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  verifyClientPurchase({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${baseUrl}/healthhubpackages/merchant/purchaseverification/${payload.phoneNumber}/${payload.verificationCode}`,
          {
            headers: {
              webAuthKey: token,
              employeeGUID: localStorage.getItem("employeeGUID"),
            },
          }
        )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getHealthHubCategory({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseUrl}/healthhubpackages/categories/${data}`, {
          headers: {
            webAuthKey: token,
            employeeGUID: localStorage.getItem("employeeGUID"),
          },
        })
        .then((response) => {
          commit("SET_CATEGORY", response.data);
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getHealthhubSubCategory({ commit }, categoryId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseUrl}/healthhubpackages/subcategories/${categoryId}/true`, {
          headers: {
            webAuthKey: token,
            employeeGUID: localStorage.getItem("employeeGUID"),
          },
        })
        .then((response) => {
          commit("SET_SUB_CATEGORY", response.data);
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getHealthHubPagination({ commit }, data) {
    return new Promise((resove, reject) => {
      axios
        .get(
          `${baseUrl}/healthhubpackages/merchant/package/${data.pageNumber}?${data.searchTerm}`,
          {
            headers: {
              webAuthKey: token,
              employeeGUID: localStorage.getItem("employeeGUID"),
            },
          }
        )
        .then((response) => {
          commit("SET_PAGINATION_DATA", response.data);
          resove(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  searchHelthHub({ commit }, searchTerm) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${baseUrl}/healthhubpackages/merchant/package/1?searchTerm=${searchTerm}`,
          {
            headers: {
              webAuthKey: token,
              employeeGUID: localStorage.getItem("employeeGUID"),
            },
          }
        )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getClientPurchasePaginatedData({ commit }, data) {
    return new Promise((resove, reject) => {
      axios
        .get(
          `${baseUrl}/healthhubpackages/merchant/packagepurchase/${data.pageNumber}?${data.searchTerm}`,
          {
            headers: {
              webAuthKey: token,
              employeeGUID: localStorage.getItem("employeeGUID"),
            },
          }
        )
        .then((response) => {
          commit("SET_PAGINATION_DATA", response.data);
          resove(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  searchClientPurchase({ commit }, searchTerm) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${baseUrl}/healthhubpackages/merchant/packagepurchase/1?seachTerm=${searchTerm}`,
          {
            headers: {
              webAuthKey: token,
              employeeGUID: localStorage.getItem("employeeGUID"),
            },
          }
        )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getHandShakeRequiredData({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseUrl}/merchantaccounts/handshake/requireddate`, {
          headers: {
            webAuthKey: token,

          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export const mutations = {
  set_New_Desc(state, desc) {
    state.desc = desc;
  },
  SET_SERVICE_FAQ(state, data) {
    state.services_faq = data;
  },

  setEmail(state, data) {
    state.businessemail = data;
  },

  ID(state, data) {
    state.businesscountryid = data;
  },

  CREATE_MERCHANT(state, userData) {
    state.user = userData;
  },
  setConfirmPassword(state, password) {
    state.confirmPassword = password;
  },
  setPhoneNumber(state, data) {
    state.phonenumber = data;
  },
  setmainPhoneNumber(state, data) {
    state.mainphonenumber = data;
  },

  setPermTag(state, data) {
    state.businesspermtag = data;
  },
  SET_PERM_TAG(state, data) {
    state.permtag = data;
  },
  VERIFY_ADMIN(state, data) {
    state.verify_admin_token = data;
  },

  setCountryCode(state, data) {
    state.selected_code = data;
  },
  SAVE_PHONE(state, data) {
    state.save_phone = data;
  },
  SET_VERIFY_OTP(state, data) {
    state.otp_mobile = data;
  },
  SET_COUNTRYID(state, data) {
    state.countryId = data;
  },

  SET_HEALTH_HUB_REQ_DATA(state, data) {
    state.health_hub_req_data = data;
  },
  SET_Healthhub_Merchant_Create(state, data) {
    state.healthhub_create = data;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_CATEGORY(state, data) {
    state.category = data;
  },
  SET_SUB_CATEGORY(state, data) {
    state.sub_category = data;
  },
  SET_PAGINATION_DATA(state, data) {
    state.paginated_data = data;
  },
  SET_ERROR(state, error) {
    state.errors = error
  }
};

export const getters = {
  getCountry: (state) => state.businesscountryid,
  gethealth_hub_req_data: (state) => state.health_hub_req_data,
  loading: (state) => state.loading,
  error: (state) => state.error,
  category: (state) => state.category,
  sub_category: (state) => state.sub_category,
  errors: (state) => state.errors,
};
