import axios from "axios";

const token = "bHDa4XaYYxOPPMMaxIttx8a8IA1q9Wy6YE8cmBMCdGWhHr/wGkvBWQ==";
const baseUrl = "https://oonpay.azurewebsites.net/api";
const merchantGUID = window.localStorage.getItem("merchantGUID");
const employeeGUID = localStorage.getItem("employeeGUID")
// const newCustomerPhoneNumberGUID = window.localStorage.getItem("newcusphone");

export const state = {
  medications: [],
  invoice: [],
  qrcode_generator:[],
  fullName: "",
  description: "",
  name:"",
  bus_email:"",
  addr:"",
  loc:"",
  web:"",
  reg:"",
  bus_num:"",
  fname:"",
  lname:"",
  emp_email:"",
  qr_full_name:"",
  qr_description:"",
  qr_cost:"",
  non_oopay_cust_guid:""
};

export const actions = {
  CustomerPhoneNum({ commit }, phone) {
    commit("setCustomerPhone", phone);
  },

  updateAdminPhone({ commit }, phone) {
    commit("setAdminPhone", phone);
  },

  updateFullname({ commit }, data) {
    commit("setFullName", data);
  },

  updateDescription({ commit }, data) {
    commit("setDescription", data);
  },
  SET_SUM({ commit }, data) {
    commit("SET_SUM", data);
  },
  updateCustomerPhoneNumber({ commit }, data) {
    commit("SET_CUS_PHONE", data);
  },
  UpdateCIN({ commit }, data) {
    commit("CustomerInternalNum", data);
  },
  UpdateDueDate({ commit }, data) {
    commit("DUE_DATE", data);
  },
  UpdateClientNum({ commit }, data) {
    commit("SET_CLIENT_NUM", data);
  },
  reduceTotalCost({ commit }, data) {
    commit("DECREASE_TOTAL", data);
  },

  UpdateBusName({ commit }, name) {
    commit("UPDATE_BUS_NAME", name);
  },
  UpdateBusEmail({ commit }, email) {
    commit("UPDATE_BUS_EMAIL", email);
  },
  UpdateBusAddress({ commit }, addr) {
    commit("UPDATE_BUS_ADDR", addr);
  },
  UpdateBusLocation({ commit }, loc) {
    commit("UPDATE_BUS_LOC", loc);
  },
  UpdateBusWeb({ commit }, web) {
    commit("UPDATE_BUS_WEB", web);
  },
  UpdateBusReg({ commit }, reg) {
    commit("UPDATE_BUS_REG", reg);
  },
  UpdateNumEmp({ commit }, num) {
    commit("UPDATE_BUS_NUM", num);
  },
  UpdateEmpFName({ commit }, fname) {
    commit("UPDATE_BUS_FNAME", fname);
  },
  UpdateEmpLName({ commit }, lname) {
    commit("UPDATE_BUS_LNAME", lname);
  },
  UpdateEmpEmail({ commit }, email) {
    commit("UPDATE_BUS_EMP_EMAIL", email);
  },
  
  UploadBusLogo({commit},logo){
    commit("UPDATE_LOGO",logo)
  },

  QrupdateFullname({ commit }, data) {
    commit("setQRFullName", data);
  },
  QrupdateDescription({ commit }, data) {
    commit("setQRDescription", data);
  },
  QRupdateCost({ commit }, data) {
    commit("setQRCost", data);
  },
  CheckboxChange({ commit }, data) {
    commit("checkbox", data);
  },

  SetNonOonpayCustGUID({ commit }, data) {
    commit("SET_NON_OONPAY_CUST_GUID", data);
  },

  VerifyCustomerPhone({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseUrl}/general/otp/mobile/${data.phonenumber}/${data.code}`, {
          headers: {
            webAuthKey: token,
          },
        })
        .then((response) => {
          commit("VERIFY_CUSTOMER", response.data);
          resolve(response);
        })
        .catch((err) => {
          // console.log(err.response.data)
          reject(err)
        });
    });
  },

  createMedication({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseUrl}/merchantservices/create`, data, {
          headers: {
            webAuthKey: token,
            merchantGUID,
          },
        })
        .then((response) => {
          commit("SET_SERVICE", response.data);
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },

  getAllMedications({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseUrl}/merchantservices/getall`, {
          headers: {
            webAuthKey: token,
            merchantGUID,
          },
        })
        .then((response) => {
          commit("SET_SERVICES", response.data);
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },

  deleteMedication({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${baseUrl}/merchantservices/delete`, {
          headers: {
            webAuthKey: token,
            merchantGUID: data.merchant,
            serviceGUID: data.serviceID,
          },
        })
        .then((respons) => {
          resolve(respons);
        })
        .catch((err) => reject(err));
    });
  },

  MedicationEdit({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${baseUrl}/merchantservices/edit`, data, {
          headers: {
            webAuthKey: token,
            merchantGUID,
          },
        })
        .then((response) => {
          commit("SET_EDIT_SERVICE", response);
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },

  GetInvoiceRequiredData({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseUrl}/invoices/merchant/getrequireddataforcreateoredit`, {
          headers: {
            webAuthKey: token,
            invoiceGUID: data.guid,
            merchantGUID,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  CreateInvoice({ commit,state }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseUrl}/invoices/merchant/create`, data, {
          headers: {
            webAuthKey: token,
            merchantGUID,
            newCustomerPhoneNumberGUID: state.non_oopay_cust_guid
          },
        })
        .then((response) => {
          resolve(response);
          commit("SET_INVOICE", response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  UpdateInvoice({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${baseUrl}/invoices/merchant/edit`, data, {
          headers: {
            webAuthKey: token,
            merchantGUID,
          },
        })
        .then((response) => {
          resolve(response);
          commit("UPDATE_INVOICE", response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  QRcode_merchant_create({commit},data){
    return new Promise((resolve,reject)=>{
      axios.post(`${baseUrl}/qrcodeitems/merchant/create`,data,{
        headers: {
          webAuthKey: token,
          merchantGUID,
           employeeGUID,
        },
      })
      .then(response=>{
        resolve(response)
        commit("SET_QRCODE_CREATE",response)
      })
      .catch(err=>{
        reject(err)
      })
    })
  },

  GetQRCodeItems({commit}){
    return new Promise((resolve,reject)=>{
      axios.get(`${baseUrl}/qrcodeitems/merchant/getall`,{
        headers: {
          webAuthKey: token,
          employeeGUID,
        },
      })
      .then(response=>{
        resolve(response)
        commit("SET_QRCODE_CREATE",response)
      })
      .catch(err=>{reject(err)})
    })
  },

  QRCodeEdit({commit},data){
    return new Promise((resolve,reject)=>{
      axios.put(`${baseUrl}/qrcodeitems/merchant/edit`,data,{
        headers: {
          webAuthKey: token,
          merchantGUID,
          employeeGUID
        },
      })
      .then(response=>{
        resolve(response)
        commit("SET_QRCODE_EDIT", response)
      })
      .catch(err=>reject(err))
    })
  },

  VerifyBusinessPhone({commit},data){
    return new Promise((resolve,reject)=>{
      axios.get(`${baseUrl}/general/otp/verify/${data}`,{
        headers: {
          webAuthKey: token,
        },
      })
      .then(response=>{
        resolve(response)
      })
      .catch(err=>reject(err))
    })
  }
  
};






export const mutations = {
  SET_SERVICE(state, data) {
    state.medications.unshift(data);
  },
  SET_SERVICES(state, data) {
    state.medications = data;
  },
  setFullName(state, data) {
    state.fullName = data;
  },
  setDescription(state, data) {
    state.description = data;
  },
  CheckboxChange(state, data) {
    state.CheckboxChange = data;
  },
  SET_EDIT_SERVICE(state, data) {
    state.service = data;
  },
  SET_SUM(state, sum) {
    state.sum = sum;
  },
  SET_CUS_PHONE(state, data) {
    state.mainphonenumber = data;
  },
  VERIFY_CUSTOMER(state, data) {
    state.verify_customer_token = data;
  },
  
  setAdminPhone(state, phone) {
    state.admin_phone_update = phone;
  },
  setCustomerPhone(state, phone) {
    state.customerphonenumber = phone;
  },
  SET_INVOICE(state, data) {
    state.invoice = data;
  },
  DUE_DATE(state, data) {
    state.due_date = data;
  },
  CustomerInternalNum(state, date) {
    state.customer_internal_num = date;
  },
  SET_CLIENT_NUM(state, data) {
    state.client_num = data;
  },
  UPDATE_INVOICE(state, data) {
    state.invoice = data;
  },
  DECREASE_TOTAL(state, data) {
    let total = state.invoice.invoice.totalCost - data;
    return total;
  },
  UPDATE_BUS_NAME(state, name) {
    state.name = name;
  },
  UPDATE_BUS_EMAIL(state, email) {
    state.bus_email = email;
  },
  UPDATE_BUS_ADDR(state, addr) {
    state.addr = addr;
  },
  UPDATE_BUS_LOC(state, loc) {
    state.loc = loc;
  },
  UPDATE_BUS_WEB(state, web) {
    state.web = web;
  },
  UPDATE_BUS_REG(state, reg) {
    state.reg = reg;
  },
  UPDATE_BUS_NUM(state, num) {
    state.bus_num = num;
  },
  UPDATE_BUS_FNAME(state, fname) {
    state.fname = fname;
  },
  UPDATE_BUS_LNAME(state, lname) {
    state.lname = lname;
  },
  UPDATE_BUS_EMP_EMAIL(state, emp_email) {
    state.emp_email = emp_email;
  },
 
  UPDATE_LOGO(state,logo){
    state.businessLogo = logo
  },

  SET_QRCODE_CREATE(state,qrcode_generator){
    state.qrcode_generator = qrcode_generator
  },
  setQRFullName(state,setQRFullName){
    state.qr_full_name = setQRFullName
  },
  setQRDescription(state,setQRDescription){
    state.qr_description = setQRDescription
  },
  setQRCost(state,setQRCost){
    state.qr_cost = setQRCost
  },
  checkbox(state,checkbox){
    state.CheckboxChange = checkbox
  },
  SET_QRCODE_EDIT(state,qrcode){
    state.qrcode = qrcode
  },
SET_NON_OONPAY_CUST_GUID(state,data){
  state.non_oopay_cust_guid = data
}
};

export const getters = {
  getAllMedications: (state) => state.medications,
  getAllQRcodes: (state) => state.qrcode_generator,
 
};
