import axios from "axios";
const baseUrl = 'https://oonpay.azurewebsites.net/api'
const webClientAuthKey = 'r6PZN23LGFlTcDiDqdu8o8_bjV9Ux2vk2pCUR01UTVd0AzFueFtLlg=='

export default {
    updateClientProfile(payload) {
        return axios.put(
            `${baseUrl}/clientaccounts/edit`, payload,
            {
                headers: {
                    webClientAuthKey
                },
            })

    },

    canpackagebepurchase(payload) {
        return axios.post(
            `${baseUrl}/foodhubpackages/client/canpackagebepurchased`, payload,
            {
                headers: {
                    webClientAuthKey,
                    clientguid: localStorage.getItem('clientguid')
                },
            })

    },

    getPhoneOTP(payload) {
        return axios.get(
            `${baseUrl}/general/otp/mobile/user/${payload}/false/true`,
            {
                headers: {
                    webClientAuthKey,
                    employeeGUID: localStorage.getItem('clientguid')
                },
            })

    },

    getEmailOTP(payload) {
        return axios.get(
            `${baseUrl}/general/otp/mail/user/${payload}/true/true`,
            {
                headers: {
                    webClientAuthKey,
                    employeeGUID: localStorage.getItem('clientguid')
                },
            })

    },

    CreateAccount(payload) {
        return axios.post(
            `${baseUrl}/clientaccounts/create`, payload.data,
            {
                headers: {
                    webClientAuthKey,
                    phoneNumberGUID: payload.phoneNumberGUID
                },
            })

    },

    resetPassword(payload) {
        return axios.put(
            `${baseUrl}/general/preset/false`, {},
            {
                headers: {
                    webClientAuthKey,
                    pass_guid: payload.pass_guid,
                    pass_passcode: payload.pass_passcode,
                    pass_password: payload.pass_password,
                },
            })

    },

    findBenefactor(payload) {
        return axios.get(
            `${baseUrl}/clientaccounts/findperson/${payload}`,
            {
                headers: {
                    webClientAuthKey,
                    clientGUID: localStorage.getItem('clientguid')
                },
            })

    },

    sendOTP(payload) {
        return axios.post(
            `${baseUrl}/general/otp/sendcode/`, payload,
            {
                headers: {
                    webClientAuthKey,
                    clientGUID: localStorage.getItem('clientguid')
                },
            })

    },

     verifyOTP(payload) {
        return axios.post(
            `${baseUrl}/general/otp/verify`, payload,
            {
                headers: {
                    webClientAuthKey,
                    clientGUID: localStorage.getItem('clientguid')
                },
            })

    },

    RequestAccountDel(payload) {
        return axios.put(
            `${baseUrl}/clientaccounts/requestaccountdelete/${payload.reason}`,payload,
            {
                headers: {
                    webClientAuthKey,
                    clientGUID: localStorage.getItem('clientguid')
                },
            })

    },


}