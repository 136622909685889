import axios from "axios";
const token = "bHDa4XaYYxOPPMMaxIttx8a8IA1q9Wy6YE8cmBMCdGWhHr/wGkvBWQ==";

export const ApiService = axios.create({

    baseURL: "https://oonpay.azurewebsites.net/api",
    headers: {
        "X-Requested-With": "XMLHttpRequest",
        webAuthKey: token,
        employeeGUID: localStorage.getItem("employeeGUID"),
        merchantGUID: localStorage.getItem("Merchantguid")
    },
    // withCredentials: true, // required to handle the CSRF token
});

