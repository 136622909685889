export default [
  {
    path: "/",
    name: "home",
    component: () => import("../views/LandingPages/Home.vue"),
  },
  {
    path: "/health-hub",
    name: "HealthHub",
    component: () => import("../views/pages/healthhub/index.vue"),
  },
  {
    path: "/foodhub",
    name: "FoodhHub",
    component: () => import("../views/pages/FoodHub/index.vue"),
  },
  {
    path: "/crowdfund",
    name: "Crowdfunding",
    component: () => import("../views/LandingPages/Crowdfunding.vue"),
  },
  {
    path: "/select-country",
    name: "SelectCountry",
    component: () => import("../views/LandingPages/SelectCountry.vue"),
  },
  {
    path: "/crowdfund-details",
    name: "CrowdFundDetails",
    component: () => import("../views/LandingPages/CrowdFundDetails.vue"),
  },
  {
    path: "/join-network",
    name: "JoinNetwork",
    component: () => import("../views/pages/joinus.vue"),
  },
  {
    path: "/register",
    name: "Master",
    component: () => import("../views/pages/account/Master.vue"),
  },
  {
    path: "/confirm-otp",
    name: "confirmOTP",
    component: () => import("../views/pages/account/confirmOTP.vue"),
  },
  {
    path: "/share-campaign",
    name: "ShareCampaign",
    component: () => import("../views/pages/crowdfunding/ShareFundRaising.vue"),
  },
  {
    path: "/crowdhub-dashboard",
    name: "crowdhubDashboard",
    component: () => import("../views/pages/crowdfunding/DashboardHome.vue"),
  },
  {
    path: "/add-validator",
    name: "AddCampaignValidator",
    component: () =>
      import("../views/pages/crowdfunding/AddCampaignValidator.vue"),
  },
  {
    path: "/fund-options",
    name: "FundOption",
    component: () => import("../views/pages/crowdfunding/FundOption.vue"),
  },
  {
    path: "/individual-funding",
    name: "Individual ",
    component: () => import("../views/pages/crowdfunding/Master.vue"),
  },

  {
    path: "/select-your-country",
    name: "SelectYourCountry",
    component: () => import("../components/CHHealthHubReg/SelectCountry.vue"),
  },
  {
    path: "/thank-you",
    name: "Thank you Page",
    component: () => import("../views/pages/thankyou"),
  },
  {
    path: "/foodhub-subcategory/:id",
    name: "FoodHub Subcategory",
    component: () => import("../views/pages/FoodHub/subcategory.vue"),
  },
  {
    path: "/add-foodhub-cart/:id",
    name: "Add FoodHub to cart",
    component: () => import("../views/pages/FoodHub/foodhubSearch.vue"),
  },

  // ##################### Main Page ROUTES ###################
  {
    path: "/about-us",
    name: "About Us",
    component: () => import("../views/pages/about-us.vue"),
  },

  {
    path: "/contact-us",
    name: "Contact Us",
    component: () => import("../views/pages/Contact.vue"),
  },

  {
    path: "/providers",
    name: "Providers",
    component: () => import("../views/pages/providers.vue"),
  },

  {
    path: "/service",
    name: "Service",
    component: () => import("../views/pages/service.vue"),
  },
  {
    path: "/terms-and-conditions",
    name: "Terms and Conditions",
    component: () => import("../views/pages/T&C.vue"),
  },

  {
    path: "/privacy",
    name: "Privacy",
    component: () => import("../views/pages/privacy.vue"),
  },

  {
    path: "/my-wallet",
    name: "My Wallet",
    component: () => import("../views/pages/Payment/myWallet.vue"),
  },

  {
    path: "/help-center",
    name: "Help Center",
    component: () => import("../views/pages/HelpCenter.vue"),
  },
  {
    path: "/create-wallet",
    name: "Create Wallet",
    component: () => import("../views/pages/Categories/createwallet.vue"),
  },

  {
    path: "/add-money",
    name: "Add money",
    component: () => import("../views/pages/Categories/addmoney.vue"),
  },
  {
    path: "/debit-card",
    name: "Debit Card",
    component: () => import("../views/pages/Categories/visadebitcard.vue"),
  },
  {
    path: "/send-money",
    name: "Send money",
    component: () => import("../views/pages/Categories/sendmoney.vue"),
  },

  

  //login page for complete business profile
  {
    path: "/complete-business-profile",
    name: "Complete Business Profile",
    component: () =>
      import("../components/CHHealthHubReg/multistepform/BPLogin.vue"),
  },

  {
    path: "/invoice-search-details",
    name: "Search Invoice",
    component: () => import("../views/pages/invoice.vue"),
  },

  {
    path: "/success",
    name: "Success Page",
    component: () => import("../components/MainView/stripe/success.vue"),
  },

  {
    path: "/profile-complete",
    name: "Profile Complete",
    component: () => import("../views/pages/thankyou/profileCompletionMessage.vue"),
  },


  // ##################### CROWDFUNDING ROUTES ###################
  {
    path: "/continue-business-profile",
    name: "CHHealthUserAccount",
    component: () => import("../components/CHHealthHubReg/CHRegHealthHub.vue"),
  },
  {
    path: "/account/merchant/login",
    name: "CHRegHealthHubLogin",
    component: () =>
      import("../components/CHHealthHubReg/CHRegHealthHubLogin.vue"),
  },

  {
    path: "/forgotten-password",
    name: "forgotPassword",
    component: () =>
      import("../components/CHHealthHubReg/multistepform/forgotPassword.vue"),
  },

  {
    path: "/new-password",
    name: "NewPassword",
    component: () =>
      import("../components/CHHealthHubReg/multistepform/newPassword.vue"),
  },

  // ##################### OONPAY Hospital Dashboard ROUTES ###################
  {
    path: "/dashboard",
    name: "Dashboard",
    meta: { requiresAuth: true },
    component: () => import("../views/pages/dashboard/index"),
  },
  {
    path: "/edit-business-profile",
    name: "Business Profile",
    meta: { requiresAuth: true },
    component: () =>
      import("../components/Dashboard/Hospital/BusinessAccountProfile.vue"),
  },
  {
    path: "/invoices",
    name: "Invoices",
    meta: { requiresAuth: true },
    component: () => import("../components/Dashboard/Hospital/invoices.vue"),
  },

  {
    path: "/create-invoices",
    name: "InvoicesForm",
    meta: { requiresAuth: true },
    component: () => import("../components/Dashboard/Hospital/invoiceForm.vue"),
  },

  {
    path: "/manage-services",
    name: "Services",
    meta: { requiresAuth: true },
    component: () =>
      import("../components/Dashboard/Hospital/Service/manage-services.vue"),
  },
  {
    path: "/manage-employees",
    name: "Employees",
    meta: { requiresAuth: true },
    component: () =>
      import("../components/Dashboard/Hospital/manage-employees.vue"),
  },
  {
    path: "/notifications",
    name: "Notification",
    meta: { requiresAuth: true },
    component: () =>
      import("../components/Dashboard/Hospital/Notification.vue"),
  },

  // ##################### OONPAY Pharmacy Dashboard ROUTES ###################

  {
    path: "/pharmacy-invoices",
    name: "PharmacyInvoices",
    meta: { requiresAuth: true },
    component: () =>
      import("../components/Dashboard/Pharmacy/pharmInvoice.vue"),
  },

  {
    path: "/generate-invoices",
    name: "PharmacyInvoicesForm",
    meta: { requiresAuth: true },
    component: () =>
      import("../components/Dashboard/Pharmacy/GenerateInvoice.vue"),
  },

  {
    path: "/manage-medication",
    name: "Medication",
    meta: { requiresAuth: true },
    component: () =>
      import("../components/Dashboard/Pharmacy/manageMedications.vue"),
  },
  {
    path: "/create-new-employee",
    name: "CreateEmpForm",
    meta: { requiresAuth: true },
    component: () =>
      import("../components/Dashboard/Hospital/createEmpForm.vue"),
  },
  {
    path: "/edit-pharmacy-invoice/:id",
    name: "EditInvoice",
    meta: { requiresAuth: true },
    component: () => import("../components/Dashboard/Pharmacy/EditInvoice.vue"),
  },
  {
    path: "/edit-hospital-invoice/:id",
    name: "EditHospitalInvoice",
    meta: { requiresAuth: true },
    component: () => import("../components/Dashboard/Hospital/editInvoice.vue"),
  },
  {
    path: "/invoice-details/:id",
    name: "Invoice Details",
    meta: { requiresAuth: true },
    component: () =>
      import("../components/Dashboard/Pharmacy/InvoiceDetails.vue"),
  },

  {
    path: "/generate-qrcode",
    name: "Generate QRCode",
    meta: { requiresAuth: true },
    component: () => import("../components/Dashboard/GenerateQRcode.vue"),
  },
  {
    path: "/admin-forget-password",
    name: "ForgotPassword",
    meta: { requiresAuth: true },
    component: () => import("../components/Dashboard/ForgetPassword.vue"),
  },
  {
    path: "/health-hub-package",
    name: "Health Hub Package",
    component: () => import("../components/HealthHub/HealthHub.vue"),
  },

  // ##################### SUPERCATEGORIES ROUTES ###################
  {
    path: "/health-supercategories",
    name: "Health Super Categories",
    component: () => import("../views/pages/healthhub/supercategories.vue"),
  },
  {
    path: "/categories/:id",
    name: "Categories",
    component: () => import("../views/pages/healthhub/categories.vue"),
  },
  {
    path: "/subcategory/:id",
    name: "Subcategories",
    component: () => import("../views/pages/healthhub/Subcategory.vue"),
  },
  {
    path: "/purchase-pack/:id",
    name: "HealthHub Search ",
    component: () => import("../views/pages/healthhub/HealthhubSearch.vue"),
  },
  {
    path: "/healthhub-checkout",
    name: "Healthhub Checkout",
    component: () => import("../views/pages/healthhub/checkout.vue"),
  },
  {
    path: "/merchants-products",
    name: "FoodHub Merchants Products",
    component: () => import("../views/pages/FoodHub/merchantProducts.vue"),
  },
  {
    path: "/foodhub-checkout",
    name: "Foodhub Checkout",
    component: () => import("../views/pages/Payment/foodHubCheckout.vue"),
  },
  {
    path: "/healthhub-merchants",
    name: "Merchants Products",
    component: () => import("../views/pages/healthhub/Merchants.vue"),
  },
  {
    path: "/view-purchased-packs",
    name: "View Purchased Packs",
    component: () => import("../views/pages/PurchasedPack"),
  },
  {
    path: "/view-healthhub-purchased-packs",
    name: "View Healthhub Purchased Packs",
    component: () => import("../views/pages/PurchasedPack/healthhubpacks.vue"),
  },
  {
    path: "/foodhub-purchased-packs-detail/:id",
    name: "FoodHub Purchased Packs Details",
    component: () => import("../views/pages/PurchasedPack/foodhubPurchasepackdetails.vue"),
  },
  {
    path: "/healthhub-purchased-packs-detail/:id",
    name: "Healthhub Purchased Packs Details",
    component: () => import("../views/pages/PurchasedPack/healthhubPurchasepackdetails.vue"),
  },
  {
    path: "/account/reset-password",
    name: "Reset Password",
    component: () => import("../views/pages/client/ResetPassword.vue"),
  },
  {
    path: "/account/update-profile",
    name: "Update Profile",
    component: () => import("../views/pages/client/EditProfile.vue"),
  },
  {
    path: "/account/client/login",
    name: "Login",
    component: () => import("../views/pages/client/login.vue"),
  },
  {
    path: "/account/client/register",
    name: "Register",
    component: () => import("../views/pages/client/Register.vue"),
  },
  {
    path: "/foodhub-packs",
    name: "FoodHub Packs",
    meta: { requiresAuth: true },
    component: () =>
      import("../components/Dashboard/FoodHub"),
  },
  {
    path: "/add-foodhub-pack",
    name: "Add FoodHub Pack",
    meta: { requiresAuth: true },
    component: () =>
      import("../components/Dashboard/FoodHub/addFoodHubPack.vue"),
  },
  {
    path: "/edit-foodhub-pack/:id",
    name: "Edit FoodHub Pack",
    meta: { requiresAuth: true },
    component: () =>
      import("../components/Dashboard/FoodHub/editFoodHubPack.vue"),
  },

  {
    path: "/our-locations",
    name: "Our locations",
    component: () =>
      import("../views/pages/ourLocations.vue"),
  },

  {
    path: "/remove-account",
    name: "Remove Account",
    component: () =>
      import("../views/pages/account/RemoveAccount.vue"),
  },


];
