import axios from "axios";

// no-unused-vars

const token = "bHDa4XaYYxOPPMMaxIttx8a8IA1q9Wy6YE8cmBMCdGWhHr/wGkvBWQ==";
const baseUrl = "https://oonpay.azurewebsites.net/api";
const merchantGUID = window.localStorage.getItem("merchantGUID");
const employeeGUID = window.localStorage.getItem("employeeGUID");
const newCustomerPhoneNumberGUID = window.localStorage.getItem("newcusphone");

export const state = {
  verify_customer_toke: null,
  customerphonenumber: null,
  customerphone: null,
  services: [],
  fullName: "",
  description: "",
  invoice: [],
  notifications: [],
  search_client_invoice: {},
  errors: {},
};

export const actions = {
  CustomerPhoneNum({ commit }, phone) {
    commit("setCustomerPhone", phone);
  },
  updateFullname({ commit }, data) {
    commit("setFullName", data);
  },
  updateDescription({ commit }, data) {
    commit("setDescription", data);
  },

  updateCustomerPhoneNumber({ commit }, data) {
    commit("setcustomerPhoneNumber", data);
  },
  CheckboxChange({ commit }, data) {
    commit("CheckboxChange", data);
  },
  UpdateCIN({ commit }, data) {
    commit("CustomerInternalNum", data);
  },
  updateStartDate({ commit }, data) {
    commit("SERVICE_START_DATE", data);
  },
  updateSEndDate({ commit }, data) {
    commit("SERVICE_END_DATE", data);
  },
  UpdateDueDate({ commit }, data) {
    commit("DUE_DATE", data);
  },
  UpdateTotalCost({ commit }, date) {
    commit("UPDATE_TOTAL_COST", date);
  },
  RemoveBenNum({ commit }, key) {
    commit("DELETE_BEN_NUM", key);
  },
  UpdateClientNum({ commit }, data) {
    commit("SET_CLIENT_NUM", data);
  },

  getAllServices({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseUrl}/merchantservices/getall`, {
          headers: {
            webAuthKey: token,
            merchantGUID,
          },
        })
        .then((response) => {
          commit("SET_SERVICES", response.data);
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },

  createService({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseUrl}/merchantservices/create`, data, {
          headers: {
            webAuthKey: token,
            merchantGUID,
          },
        })
        .then((response) => {
          commit("SET_SERVICE", response.data);
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },

  VerifyCustomerPhone({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseUrl}/general/otp/mobile/${data.phonenumber}/${data.code}`, {
          headers: {
            webAuthKey: token,
          },
        })
        .then((response) => {
          commit("VERIFY_CUSTOMER", response);
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },

  serviceEdit({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${baseUrl}/merchantservices/edit`, data, {
          headers: {
            webAuthKey: token,
            merchantGUID,
          },
        })
        .then((response) => {
          commit("SET_EDIT_SERVICE", response);
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  deleteService({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${baseUrl}/merchantservices/delete`, {
          headers: {
            webAuthKey: token,
            merchantGUID: data.merchant,
            serviceGUID: data.serviceID,
          },
        })
        .then((respons) => {
          resolve(respons);
        })
        .catch((err) => reject(err));
    });
  },
  GetInvoiceRequiredData({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseUrl}/invoices/merchant/getrequireddataforcreateoredit`, {
          headers: {
            webAuthKey: token,
            invoiceGUID: data.guid,
            merchantGUID,
          },
        })
        .then((response) => {
          resolve(response);
          commit("GET_REQUIRED_DATA", response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  GetBenefactor({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseUrl}/invoices/merchant/benefactor/${data.phonenumber}`, {
          headers: {
            webAuthKey: token,
            invoiceGUID: 0,
            merchantGUID,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  CreateInvoice({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseUrl}/invoices/merchant/create`, data, {
          headers: {
            webAuthKey: token,
            merchantGUID,
            newCustomerPhoneNumberGUID,
          },
        })
        .then((response) => {
          resolve(response);
          commit("SET_INVOICE", response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  UpdateInvoice({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${baseUrl}/invoices/merchant/edit`, data, {
          headers: {
            webAuthKey: token,
            merchantGUID,
          },
        })
        .then((response) => {
          resolve(response);
          commit("UPDATE_INVOICE", response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getAllInvoice({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseUrl}/invoices/merchant`, {
          headers: {
            webAuthKey: token,
            merchantGUID,
          },
        })
        .then((response) => {
          resolve(response);
          commit("SET_INVOICE", response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getAllNotifications({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseUrl}/notifications/merchant/getall`, {
          headers: {
            webAuthKey: token,
            merchantGUID,
          },
        })
        .then((response) => {
          commit("SET_NOTIFICATION", response);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getInvoiceDetails({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseUrl}/invoices/merchant/details`, {
          headers: {
            webAuthKey: token,
            employeeGUID,
            invoiceGUID: data,
          },
        })
        .then((response) => {
          commit("SET_INVOICE_DETAILS", response);
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  getProfile({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseUrl}/merchantaccounts/getrequireddataforedit`, {
          headers: {
            webAuthKey: token,
            employeeGUID,
            merchantGUID,
          },
        })
        .then((response) => {
          commit("SET_PROFILE", response);
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },

  updateProfile({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${baseUrl}/merchantaccounts/edit`, data, {
          headers: {
            webAuthKey: token,
          },
        })
        .then((response) => {
          resolve(response);
          commit("SET_PROFILE", response);
        })
        .catch((err) => reject(err));
    });
  },

  //Search for invoice on homepage
  searchClientInvoice({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseUrl}/invoices/client/paytagexists/${data}`, {
          headers: {
            webAuthKey: token,
          },
        })
        .then((response) => {
          // commit("SET_SEARCH_ITEM", response);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  async getSearchedInvoiceDetails({ commit }, data) {
    try {
      const response = await axios
      .get(`${baseUrl}/invoices/client/invoicebypaytag/${data.searchTerm}`, {
        headers: {
          webAuthKey: token,
          otp: data.otp,
          phoneNumber: data.phonenumber,
        },
      })

      if(response.status == 200){
        //console.log("res",response)
        localStorage.setItem("invoice", JSON.stringify(response.data));
        location.href="/invoice-search-details"
      }

      
    } catch (e) {
       commit("SET_ERROR",e.response)
    }
  },

  // getSearchedInvoiceDetails({ commit }, data) {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .get(`${baseUrl}/invoices/client/invoicebypaytag/${data.searchTerm}`, {
  //         headers: {
  //           webAuthKey: token,
  //           otp: data.otp,
  //           phoneNumber: data.phonenumber,
  //         },
  //       })
  //       .then((response) => {
  //         console.log(response)
  //         commit("SET_SEARCH_INVOICE_DETAILS", response);
  //         resolve(response);
  //       })
  //       .catch((e) => {
  //         console.log("jakjskaj",e)
  //         reject(e)
  //       });
  //   });
  // },

  getSecurite({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${baseUrl}/clienttransactions/payments/stripewebpayment`,
          payload,
          {
            headers: {
              webAuthKey: token,
            },
          }
        )
        .then((response) => {
          

          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
};

export const mutations = {
  VERIFY_CUSTOMER(state, data) {
    state.verify_customer_toke = data;
  },
  setCustomerPhone(state, phone) {
    state.customerphonenumber = phone;
  },
  setcustomerPhoneNumber(state, data) {
    state.customerphone = data;
  },
  SET_SERVICES(state, data) {
    state.services = data;
  },
  SET_SERVICE(state, data) {
    state.services.unshift(data);
  },
  setFullName(state, data) {
    state.fullName = data;
  },
  setDescription(state, data) {
    state.description = data;
  },
  CheckboxChange(state, data) {
    state.CheckboxChange = data;
  },
  SET_EDIT_SERVICE(state, data) {
    state.service = data;
  },
  SET_INVOICE(state, data) {
    state.invoice = data;
  },
  SET_NOTIFICATION(state, data) {
    state.notifications = data;
  },
  CustomerInternalNum(state, date) {
    state.customer_internal_num = date;
  },
  SERVICE_START_DATE(state, data) {
    state.service_start_date = data;
  },
  SERVICE_END_DATE(state, date) {
    state.service_end_date = date;
  },
  DUE_DATE(state, data) {
    state.due_date = data;
  },
  UPDATE_TOTAL_COST(state, data) {
    state.total_cost = data;
  },
  GET_REQUIRED_DATA(state, data) {
    state.required_data = data;
  },
  DELETE_BEN_NUM(state, key) {
    state.invoice.required_data.item3.splice(key, 1);
  },
  SET_CLIENT_NUM(state, data) {
    state.client_num = data;
  },
  UPDATE_INVOICE(state, data) {
    state.invoice = data;
  },
  SET_INVOICE_DETAILS(state, data) {
    state.invoice_details = data;
  },
  SET_PROFILE(state, data) {
    state.profile = data;
  },
  SET_SEARCH_INVOICE_DETAILS(state, data) {
    state.search_client_invoice = data;
  },
  SET_ERROR(state,error){
    state.errors = error
  }
};

export const getters = {
  getAllServices: (state) => state.services,
  getInvoiceDetails: (state) => state.search_client_invoice,
  errors: (state) => state.errors,
};
