<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import appConfig from "@/app.config";

export default {
  name: "app",
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    }
  }
};
</script>

<style >
body[data-sidebar=dark] .vertical-menu, .navbar-brand-box{
  background: rgb(3, 36, 77) !important;
  color: #fff;
}

body, html{
  color: #180e34;
  font-family: Poppins,sans-serif;
  
}

input:disabled {
  background-color: #e6e3e1;
}

.btn-primary, .btn-success{
   background: rgb(3, 36, 77) !important;
  border: none !important;
}

.btn-danger{
   background: #f15825 !important;
  border: none !important;
}


</style>