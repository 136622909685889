import axios from "axios";
const baseUrl = 'https://oonpay.azurewebsites.net/api'
const webClientAuthKey = 'r6PZN23LGFlTcDiDqdu8o8_bjV9Ux2vk2pCUR01UTVd0AzFueFtLlg=='
const clientguid = localStorage.getItem("account_info") ? JSON.parse(localStorage.getItem("account_info")).guid : ""


export default {
    getHealthhubSupercategory() {
        return axios.get(
            `${baseUrl}/healthhubpackages/supercategories/getall`,
            {
                headers: {
                    webClientAuthKey
                },
            })

    },
    getCategorie(id) {
        return axios.get(
            `${baseUrl}/healthhubpackages/categories/${id}`,
            {
                headers: {
                    webClientAuthKey
                },
            })
    },

    getSubCategorie(id) {
        return axios.get(
            `${baseUrl}/healthhubpackages/subcategories/${id}/false`,
            {
                headers: {
                    webClientAuthKey
                },
            })
    },

    getRequiredData(id) {
        return axios.get(
            `${baseUrl}/healthhubpackages/requireddataforpackagespecification/${id}`,
            {
                headers: {
                    webClientAuthKey
                },
            })
    },

    searchPack(payload) {
        return axios.put(`${baseUrl}/healthhubpackages/webclient/packagesbyspecification`, payload, {
            headers: {
                webClientAuthKey: 'r6PZN23LGFlTcDiDqdu8o8_bjV9Ux2vk2pCUR01UTVd0AzFueFtLlg==',
                clientguid: localStorage.getItem('clientguid')
            },
        })
    },
    getClientPurchases(id) {
        return axios.get(
            `${baseUrl}/healthhubpackages/client/purchasedpack`,
            {
                headers: {
                    webClientAuthKey: 'r6PZN23LGFlTcDiDqdu8o8_bjV9Ux2vk2pCUR01UTVd0AzFueFtLlg==',
                    clientguid: localStorage.getItem('clientguid')
                },
            })
    },

    canpackagebepurchase(payload) {
        return axios.post(
            `${baseUrl}/healthhubpackages/client/canpackagebepurchased`, payload,
            {
                headers: {
                    webClientAuthKey,
                    clientguid: localStorage.getItem('clientguid')
                },
            })

    },

    purchasePackDetail(id) {
        return axios.get(`${baseUrl}/healthhubpackages/client/purchasedpackdetail`, {
            headers: {
                webClientAuthKey,
                purchaseGUID: id
            },
        })
    },

    usePackage(payload) {
        return axios.get(`${baseUrl}/healthhubpackages/client/purchaseverificationcode`, {
            headers: {
                webClientAuthKey,
                clientguid,
                purchaseGUID: payload
            },
        })
    },

    SearchPackDetail(payload) {
        return axios.put(`${baseUrl}/healthhubpackages/client/packagesbyspecification/detail`, payload, {
            headers: {
                webClientAuthKey,
                clientguid
            },
        })
    },



}