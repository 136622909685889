import axios from "axios";
const baseUrl = 'https://oonpay.azurewebsites.net/api'
const webClientAuthKey = 'r6PZN23LGFlTcDiDqdu8o8_bjV9Ux2vk2pCUR01UTVd0AzFueFtLlg=='
const clientguid = localStorage.getItem("account_info") ? JSON.parse(localStorage.getItem("account_info")).guid : ""

export default {
    getFoodhubSupercategory() {
        return axios.get(
            `${baseUrl}/foodhubpackages/client/supercategories`,
            {
                headers: {
                    webClientAuthKey,
                },
            })

    },
    getSubCategorie(id) {
        return axios.get(
            `${baseUrl}/foodhubpackages/subcategories/${id}`,
            {
                headers: {
                    webClientAuthKey,
                },
            })
    },

    ClientAccountCreate(payload) {
        return axios.post(`${baseUrl}/clientaccounts/create`, payload.data, {
            headers: {
                webClientAuthKey,
                phoneNumberGUID: payload.phoneNumberGUID,
            },
        })
    },

    ClientLoginWithPass(payload) {
        return axios.get(`${baseUrl}/clientaccounts/signin/password`, {
            headers: {
                'pass-email': payload.email,
                'pass-password': payload.password,
                webClientAuthKey
            },
        })
    },

    ClientLoginWithPhone(payload) {
        return axios.post(`${baseUrl}/clientaccounts/signin/${payload.guid}`,payload, {
            headers: {
                webClientAuthKey,

            },
        })
    },

    GetOTPMobile(payload) {
        return axios.get(`${baseUrl}/general/otp/mobile/userlogin/${payload.phoneNumber}/${payload.callCode}`, {
            headers: {
                webClientAuthKey
            },
        })
    },

    searchBeneficiaryDetail(payload) {
        return axios.get(`${baseUrl}/clientaccounts/findname/${payload}`, {
            headers: {
                webClientAuthKey
            },
        })
    },
    searchPack(payload) {
        return axios.put(`${baseUrl}/foodhubpackages/client/packagesbyspecification`, payload, {
            headers: {
                webClientAuthKey,
                clientguid
            },
        })
    },

    SearchPackDetail(payload) {
        return axios.put(`${baseUrl}/foodhubpackages/client/packagesbyspecification/detail`, payload, {
            headers: {
                webClientAuthKey,
                clientguid
            },
        })
    },

    getClientPurchases(id) {
        return axios.get(
            `${baseUrl}/foodhubpackages/client/purchasedpack`,
            {
                headers: {
                    webClientAuthKey,
                    clientguid
                },
            })
    },

    usePackage(payload) {
        return axios.get(`${baseUrl}/foodhubpackages/client/purchaseverificationcode`, {
            headers: {
                webClientAuthKey,
                clientguid,
                purchaseGUID: payload
            },
        })
    },

    purchasePackDetail(id) {
        return axios.get(`${baseUrl}/foodhubpackages/client/purchasedpackdetail`, {
            headers: {
                webClientAuthKey,
                purchaseGUID: id
            },
        })
    },

    getRequiredData() {
        return axios.get(`${baseUrl}/foodhubpackages/merchant/requireddataforcreateoredit`, {
            headers: {
                webClientAuthKey,
                employeeGUID: localStorage.getItem("employeeGUID"),
                merchantGUID: localStorage.getItem("Merchantguid")
            },
        })
    },
    getFoodHubSubCategory(id) {
        return axios.get(`${baseUrl}/foodhubpackages/subcategories/${id}`, {
            headers: {
                webClientAuthKey,
                employeeGUID: localStorage.getItem("employeeGUID"),
                merchantGUID: localStorage.getItem("Merchantguid")
            },
        })

    },
    addFoodHub(payload) {
        return axios.post(`${baseUrl}/foodhubpackages/merchant/create`, payload, {
            headers: {
                webClientAuthKey,
                employeeGUID: localStorage.getItem("employeeGUID"),
                merchantGUID: localStorage.getItem("Merchantguid")
            },
        })

    },

    updateFoodHub(payload) {
        return axios.put(`${baseUrl}/foodhubpackages/merchant/edit`, payload, {
            headers: {
                webClientAuthKey,
                employeeGUID: localStorage.getItem("employeeGUID"),
                merchantGUID: localStorage.getItem("Merchantguid")
            },
        })

    },

    getPackageImages(id) {
        return axios.get(`${baseUrl}/foodhubpackages/merchant/packageimages/${id}`, {
            headers: {
                webClientAuthKey,
                employeeGUID: localStorage.getItem("employeeGUID"),
                merchantGUID: localStorage.getItem("Merchantguid")
            },
        })

    },

    verifyClientPurchase(payload) {
        return axios.get(`${baseUrl}/foodhubpackages/merchant/purchaseverification/${payload.phoneNumber}/${payload.verificationCode}`, {
            headers: {
                webClientAuthKey,
                employeeGUID: localStorage.getItem("employeeGUID"),
                merchantGUID: localStorage.getItem("Merchantguid")
            },
        })
    }
}