import axios from "axios";

// no-unused-vars

const token = "bHDa4XaYYxOPPMMaxIttx8a8IA1q9Wy6YE8cmBMCdGWhHr/wGkvBWQ==";
const baseUrl = "https://oonpay.azurewebsites.net/api";
const merchantGUID = window.localStorage.getItem("merchantGUID");
const employeeGUID = window.localStorage.getItem("employeeGUID");

export const state = {
  revenue: {},
  email_otp: "",
  phone_otp: "",
  all_countries: [],
  transactiontype: [],
  transaction: [],
};

export const actions = {
  getRevenue({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${baseUrl}/merchantdashboard/revenue/${data.startDate}/${data.endDate}`,
          {
            headers: {
              webAuthKey: token,
              merchantGUID,
              employeeGUID,
            },
          }
        )
        .then((response) => {
          commit("SET_REVENUE", response);

          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  //    Reset Password Via Email
  EmailOTP({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseUrl}/general/otp/mail/user/${data}/true/true`, {
          headers: {
            webAuthKey: token,
            merchantGUID,
            employeeGUID,
          },
        })
        .then((response) => {
          commit("SET_EMAIL_OTP", response);
          resolve(response);
          window.localStorage.setItem("pass_guid", response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  //    Reset Password Via Phone
  PhoneOTP({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseUrl}/general/otp/mobile/user/${data}/true/true`, {
          headers: {
            webAuthKey: token,
            merchantGUID,
            employeeGUID,
          },
        })
        .then((response) => {
          commit("SET_PHONE_OTP", response);
          window.localStorage.setItem("pass_guid", response.data);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Update password
  ResetPassword({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        method: "put",
        url: `${baseUrl}/general/preset/true`,
        data: null,
        headers: {
          pass_guid: window.localStorage.getItem("pass_guid"),
          pass_passcode: data.pass_passcode,
          pass_password: data.pass_password,
          webAuthKey: token,
        },
      })
        .then((response) => {
          commit("SET_RESET_PASSWORD", response);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  //getallcountries
  GetAllCountries({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseUrl}/general/countries/getall`, {
          headers: {
            webAuthKey: token,
          },
        })
        .then(({ data }) => {
          commit("SET_COUNTRIES", data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getTransactionType({ commit },id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseUrl}/merchantdashboard/itemtypes/${id}`, {
          headers: {
            webAuthKey: token,
          },
        })
        .then(({ data }) => {
          commit("SET_TRANSACTION_TYPE", data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getTransactions({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${baseUrl}/merchantdashboard/transactions/${payload.dashboardItemType}/${payload.startDate}/${payload.endDate}`,
          {
            headers: {
              webAuthKey: token,
              employeeGUID,
            },
          }
        )
        .then(({ data }) => {
          commit("SET_TRANSACTIONS", data);
          console.log("Trans",data)
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export const mutations = {
  SET_REVENUE(state, revenue) {
    state.revenue = revenue;
  },
  SET_EMAIL_OTP(state, data) {
    state.email_otp = data;
  },
  SET_PHONE_OTP(state, data) {
    state.phone_otp = data;
  },
  SET_RESET_PASSWORD(state, password) {
    state.reset_password = password;
  },
  SET_COUNTRIES(state, data) {
    state.all_countries = data;
  },
  SET_TRANSACTION_TYPE(state, data) {
    state.transactiontype = data;
  },
  SET_TRANSACTIONS(state, data) {
    state.transaction = data;
  },
};

export const getters = {
  all_countries: (state) => state.all_countries,
  transaction_type: (state) => state.transactiontype,
  transactions: (state) => state.transaction,
};
