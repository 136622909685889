import { ApiService } from "./ApiService";


export default {
    getFoodHubDashboard(payload) {
        return ApiService.get(
            `foodhubpackages/merchant/revenue/${payload.startDate}/${payload.endDate}`,
        );
    },

    getTransactions(payload) {
        return ApiService.get(
            `merchantdashboard/transactions/3/${payload.startDate}/${payload.endDate}`,
        );
    },

};
