import axios from "axios";
const baseUrl = "https://oonpay.azurewebsites.net/api";
const webAuthKey = "bHDa4XaYYxOPPMMaxIttx8a8IA1q9Wy6YE8cmBMCdGWhHr/wGkvBWQ==";
const merchantGUID = window.localStorage.getItem("merchantGUID");

export const state = {
  UserFirstName: "",
  UserLastName: "",
  CrowdEmail: "",
  CrowdPassword: "",
  merchant_info: {},
};

export const actions = {
  setUserFirstName({ commit }, firstName) {
    commit("setUserFirstName", firstName);
  },
  setUserLastName({ commit }, lastName) {
    commit("setUserLastName", lastName);
  },
  setCrowdEmail({ commit }, email) {
    commit("setCrowdEmail", email);
  },
  setCrowdPassword({ commit }, password) {
    commit("setCrowdPassword", password);
  },

  Login({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseUrl}/merchantaccounts/signin`, {
          headers: {
            webAuthKey,
            pass_email: data.pass_email,
            pass_password: data.pass_password,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  DownloadQRCode({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseUrl}/qrcodeitems/merchant/generateqrcode`, {
          headers: {
            webAuthKey,
            merchantGUID,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },

  MerchantHandshake({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${baseUrl}/merchantaccounts/handshake/create`,
          payload,
          {
            headers: {
              webAuthKey,
              businessPhoneNumberGUID: localStorage.getItem(
                "businessPhoneNumberGUID"
              ),
            },
          }
        )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },

  merchantOnboardingSign({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseUrl}/merchantaccounts/onboarding/signin/${payload.emailaddress}`, {
          headers: {
            webAuthKey,
            pass_password: payload.password,
          },
        })
        .then((response) => {
         localStorage.setItem('merchant_info',JSON.stringify(response.data) )
          commit("SET_MERCHANT_INFO", response.data);
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
};

export const mutations = {
  setCrowdPassword(state, password) {
    state.CrowdPassword = password;
  },
  setUserFirstName(state, firstName) {
    state.UserFirstName = firstName;
  },
  setUserLastName(state, lastname) {
    state.UserLastName = lastname;
  },
  setCrowdEmail(state, email) {
    state.CrowdEmail = email;
  },
  SET_MERCHANT_INFO(state, data) {
    state.merchant_info = data;
  },
};

export const getters = {
  get_merchant_info: (state) => state.merchant_info,
  //getCountry: (state) => state.businesscountryid,
};
