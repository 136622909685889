import defaultService from "../../service/defaultService"

export const state = {
    account: {},
    islogin: "",
    package: "",
    usebtn:null
}

export const actions = {
    getAccountInfo({ commit }, data) {
        commit("SET_ACCOUNT_INFO", data)
        localStorage.setItem("account_info", JSON.stringify(data))
    },

    setLoginStatus({ commit }, data) {
        commit("SET_LOGIN_STATUS", data)
        localStorage.setItem("login_status", data)
    },
    setPackType({ commit }, payload) {
        commit("SET_PACKAGE_TYPE", payload)
        localStorage.setItem("pack_type", payload)
    },

    setShowbtn({ commit }, payload){
         commit("SET_USEBTN_STATUS", JSON.stringify(payload))
        localStorage.setItem("showusebtn", payload)
    },

    updateClientProfile({ commit }, payload) {
        return new Promise((resolve, reject) => {
            defaultService.updateClientProfile(payload)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    CreateAccount({ commit }, payload) {
        return new Promise((resolve, reject) => {
            defaultService.CreateAccount(payload)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    canpackagebepurchase({ commit }, payload) {
        return new Promise((resolve, reject) => {
            defaultService.canpackagebepurchase(payload)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    getPhoneOTP({ commit }, payload) {
        return new Promise((resolve, reject) => {
            defaultService.getPhoneOTP(payload)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    getEmailOTP({ commit }, payload) {
        return new Promise((resolve, reject) => {
            defaultService.getEmailOTP(payload)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    resetPassword({ commit }, payload) {
        return new Promise((resolve, reject) => {
            defaultService.resetPassword(payload)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    findBenefactor({ commit }, payload) {
        return new Promise((resolve, reject) => {
            defaultService.findBenefactor(payload)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    sendOTP({ commit }, payload) {
        return new Promise((resolve, reject) => {
            defaultService.sendOTP(payload)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

     verifyOTP({ commit }, payload) {
        return new Promise((resolve, reject) => {
            defaultService.verifyOTP(payload)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    RequestAccountDel({commit},payload){
        return new Promise((resolve, reject) => {
            defaultService.RequestAccountDel(payload)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    }
}


export const mutations = {
    SET_ACCOUNT_INFO(state, data) {
        state.account = data
    },

    SET_LOGIN_STATUS(state, data) {
        state.islogin = data
    },

    SET_PACKAGE_TYPE(state, data) {
        state.package = data
    },
    SET_USEBTN_STATUS(state, data) {
        state.usebtn = data
    },

}

export const getters = {
    account: state => state.account,
    islogin: state => state.islogin,
    btnStatus: state => state.usebtn,
}