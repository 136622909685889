import healthhub from "../../service/healthhub"


export const state = {
    supercategories: [],
    subcategories: [],
    categories: [],
    categoryID:""

}

export const actions = {
    setCategoryId({ commit }, id) {
        commit("SET_CATEGORY_ID", id)
    },

    getHealthhubSupercategory({ commit }) {
        return new Promise((resolve, reject) => {
            healthhub.getHealthhubSupercategory()
                .then(res => {
                    commit("SET_HEALTH_HUB_SUPER_CATEGORIES", res.data)
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    getCategorie({ commit }, id) {
        return new Promise((resolve, reject) => {
            healthhub.getCategorie(id)
                .then(res => {
                    commit("SET_HEALTH_HUB_CATEGORIES", res.data)
                    console.log(res.data)
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    getSubCategorie({ commit }, id) {
        return new Promise((resolve, reject) => {
            healthhub.getSubCategorie(id)
                .then(res => {
                    commit("SET_HEALTH_HUB_SUBCATEGORIES", res.data)
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    getRequiredData({ commit }, payload) {
        return new Promise((resolve, reject) => {
            healthhub.getRequiredData(payload)
                .then(res => {
                    resolve(res)
                    // console.log(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    SearchPack({ commit }, payload) {
        return new Promise((resolve, reject) => {
            healthhub.searchPack(payload)
                .then(res => {
                    resolve(res)
                    // console.log(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    getHealthHubClientPurchases({ commit }) {
        return new Promise((resolve, reject) => {
            healthhub.getClientPurchases()
                .then(res => {
                    resolve(res)
                    // console.log(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    canpackagebepurchase({ commit }, payload) {
        return new Promise((resolve, reject) => {
            healthhub.canpackagebepurchase(payload)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    purchasePackDetail({ commit }, id) {
        return new Promise((resolve, reject) => {
            healthhub.purchasePackDetail(id)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    usePackage({ commit }, payload) {
        return new Promise((resolve, reject) => {
            healthhub.usePackage(payload)
                .then(res => {
                    resolve(res)
                    // console.log(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    SearchPackDetail({ commit }, payload) {
        return new Promise((resolve, reject) => {
            healthhub.SearchPackDetail(payload)
                .then(res => {
                    resolve(res)
                    // console.log(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },



}


export const mutations = {
    SET_HEALTH_HUB_SUPER_CATEGORIES(state, data) {
        state.supercategories = data
    },
    SET_HEALTH_HUB_CATEGORIES(state, data) {
        state.categories = data
    },
    SET_HEALTH_HUB_SUBCATEGORIES(state, data) {
        state.subcategories = data
    },
    SET_CATEGORY_ID(state,id){
        state.categoryID = id
    }

}

export const getters = {
    supercategories: state => state.supercategories,
    categories: state => state.categories,
    subcategories: state => state.subcategories,
    subcategoryid: state => state.categoryID
}