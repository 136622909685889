import Vue from "vue";
import App from "./App.vue";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import BootstrapVue from "bootstrap-vue";
import "./assets/css/main.css";
import Vuelidate from "vuelidate";
import VueSweetalert2 from "vue-sweetalert2";
import VueMask from "v-mask";
import Toasted from 'vue-toasted'
import moment from 'moment'
import VueGoogleAutocomplete from "vue-google-autocomplete";
import VueLazyload from 'vue-lazyload'

// import * as VueGoogleMaps from 'vue2-google-maps'


import vco from "v-click-outside";

import router from "./router";
import store from "@/state/store";
import i18n from "./i18n";
import "@/assets/scss/app.scss";



Vue.use(VueGoogleAutocomplete, {
  apiKey: "AIzaSyABmW5XJTsyoq8WrCQlXzZbyrHgyiJziXo",
});

Vue.use(VueLazyload, {
   preLoad: 1.3,
  loading: './assets/Spinner.gif',
  attempt: 1
})

Vue.use(VueTelInput);
Vue.config.productionTip = false;
Vue.use(Toasted)
Vue.use(BootstrapVue);
Vue.use(vco);
Vue.use(Vuelidate);
Vue.use(VueSweetalert2);
Vue.use(VueMask);

Vue.filter('formatDate',function(value,formatType = 'LL'){
  if(!value) return ''
  return moment(value).format(formatType)
})

Vue.filter('formatDate2', function(value) {
  if (value) {
    return moment(String(value)).format('MM/DDD/YYYY hh:mm')
  }
})

// Vue.use(VueGoogleMaps, {
//   load: {
//     key: 'AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE',
//     libraries: 'places',
//   },
//   installComponents: true
// })

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
