import axios from "axios";

const token = "bHDa4XaYYxOPPMMaxIttx8a8IA1q9Wy6YE8cmBMCdGWhHr/wGkvBWQ=="
const baseUrl = 'https://oonpay.azurewebsites.net/api'
const merchantGUID = window.localStorage.getItem("merchantGUID")
const employeeGUID = localStorage.getItem("employeeGUID")
// const employeePhoneNumberGUID = localStorage.getItem("adminguid")


export const state = {
    employees: [],
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    CheckboxChange: "",
    phonenumber:""

}

export const actions = {
    UpdateFirstName({ commit }, firstName) {
        commit('SET_FIRST_NAME', firstName)
    },

    UpdateLastName({ commit }, lastName) {
        commit("SET_LAST_NAME", lastName)
    },

    UpdateEmail({ commit }, email) {
        commit("SET_EMAIL", email)
    },

    UpdatePhone({ commit }, phone) {
        commit("SET_PHONE", phone)
    },

    CheckboxChange({ commit }, data) {
        commit("SET_CHECKBOX", data)
    },

    VerifyPhone({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios.get(`${baseUrl}/general/otp/mobile/${data.phonenumber}/${data.code}`, {
                headers: {
                    webAuthKey: token,

                }
            })
                .then(response => {
                    commit("VERIFY_PHONE", response)
                    resolve(response)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    SetPhoneHumber({commit}, data){
        commit("SET_PHONE_NUM",data)
    },


    CreateEmp({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios.post(`${baseUrl}/merchantaccounts/employees/create`, data, {
                headers: {
                    webAuthKey: token,
                    merchantGUID,
                    employeeGUID,
                    employeePhoneNumberGUID: data.empguid
                }
            })
                .then(response => {
                    commit("SET_EMPLOYEES", response)
                    resolve(response)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    getAllEmployees({ commit }) {
        return new Promise((resolve, reject) => {
            axios.get(`${baseUrl}/merchantaccounts/employees/getall`, {
                headers: {
                    webAuthKey: token,
                    merchantGUID,
                    employeeGUID
                }
            })
                .then(response => {
                    resolve(response)
                    commit("GET_EMPLOYEES", response.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    UpdateEmpData({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios.put(`${baseUrl}/merchantaccounts/employees/edit`, data, {
                headers: {
                    webAuthKey: token,
                    merchantGUID,
                    employeeGUID
                }
            })
                .then(response => {
                    resolve(response)
                    commit("SET_EMP_DATA", response)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    
}

export const mutations = {
    SET_EMPLOYEES(state, data) {
        state.employees.push(data)
    },
    GET_EMPLOYEES(state, data) {
        state.employees = data
    },
    SET_FIRST_NAME(state, firstname) {
        state.firstName = firstname
    },
    SET_LAST_NAME(state, lastname) {
        state.lastName = lastname
    },
    SET_EMAIL(state, email) {
        state.email = email
    },
    SET_PHONE(state, phone) {
        state.phone = phone
    },
    SET_CHECKBOX(state, data) {
        state.CheckboxChange = data
    },
    SET_EMP_DATA(state, data) {
        state.employees = data
    },
    VERIFY_PHONE(state, data){
        state.veryphone = data
    },
    SET_PHONE_NUM(state, data){
        state.phonenumber = data
    }
}

export const getters = {
    getAllEmp: state => state.employees
}