import Dashboard from "../../service/Dashboard"
import foodhub from "../../service/foodhub"


export const state = {
    supercategories: [],
    subcategories: [],
    revenue: [],
    transactions: [],
    auth: {},
    login: {},
    loading: false,
    error: null,
    foodhub: [],
    subcategoryid: ''
}

export const actions = {

    getFoodHubSuperCategories({ commit }) {
        return new Promise((resolve, reject) => {
            foodhub.getFoodhubSupercategory()
                .then(res => {
                    commit("SET_FOOD_HUB_SUPER_CAT", res.data)
                    resolve(res)

                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    getFoodHubSuberCategories({ commit }, id) {
        return new Promise((resolve, reject) => {
            foodhub.getSubCategorie(id)
                .then(res => {
                    commit("SET_FOOD_HUB_SUB_CAT", res.data)
                    resolve(res)

                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    CreateAccount({ commit }, payload) {
        return new Promise((resolve, reject) => {
            foodhub.ClientAccountCreate(payload)
                .then(res => {
                    commit("SET_ACCOUNT", res.data)
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    LoginWithPass({ commit }, payload) {
        return new Promise((resolve, reject) => {
            foodhub.ClientLoginWithPass(payload)
                .then(res => {
                    commit("SET_LOGIN", res.data)
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    LoginWithPhone({ commit }, payload) {
        return new Promise((resolve, reject) => {
            foodhub.ClientLoginWithPhone(payload)
                .then(res => {
                    commit("SET_LOGIN_WITH_PHONE", res.data)
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    GetOTP({ commit }, payload) {
        return new Promise((resolve, reject) => {
            foodhub.GetOTPMobile(payload)
                .then(res => {
                    resolve(res)
                    // console.log(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    BeneficiaryDetails({ commit }, payload) {
        return new Promise((resolve, reject) => {
            foodhub.searchBeneficiaryDetail(payload)
                .then(res => {
                    resolve(res)
                    // console.log(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    SearchPack({ commit }, payload) {
        return new Promise((resolve, reject) => {
            foodhub.searchPack(payload)
                .then(res => {
                    resolve(res)
                    // console.log(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    SearchPackDetail({ commit }, payload) {
        return new Promise((resolve, reject) => {
            foodhub.SearchPackDetail(payload)
                .then(res => {
                    resolve(res)
                    // console.log(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    getFoodHubClientPurchases({ commit }) {
        return new Promise((resolve, reject) => {
            foodhub.getClientPurchases()
                .then(res => {
                    resolve(res)
                    // console.log(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    usePackage({ commit }, payload) {
        return new Promise((resolve, reject) => {
            foodhub.usePackage(payload)
                .then(res => {
                    resolve(res)
                    // console.log(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    purchasePackDetail({ commit }, id) {
        return new Promise((resolve, reject) => {
            foodhub.purchasePackDetail(id)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    getDashboardData({ commit }, payload) {
        commit("SET_LOADING", true)
        return new Promise((resolve, reject) => {
            Dashboard.getFoodHubDashboard(payload)
                .then(res => {
                    commit("SET_DASHBOARD_DATA", res)
                    resolve(res)
                })
                .catch((err) => {
                    commit("SET_ERROR", err);
                    reject(err);
                })
                .finally(() => commit("SET_LOADING", false))
        })
    },

    getRequiredData({ commit }) {
        commit("SET_LOADING", true)
        return new Promise((resolve, reject) => {
            foodhub.getRequiredData()
                .then(res => {
                    commit("SET_REQUIEDDATA", res)

                    resolve(res)
                })
                .catch((err) => {
                    console.log(err)
                    commit("SET_ERROR", err);
                    reject(err);
                })
                .finally(() => commit("SET_LOADING", false))
        })
    },

    getFoodHubSubCat({ commit }, id) {
        commit("SET_LOADING", true)
        return new Promise((resolve, reject) => {
            foodhub.getFoodHubSubCategory(id)
                .then(res => {
                    resolve(res)
                })
                .catch((err) => {
                    commit("SET_ERROR", err);
                    reject(err);
                })
                .finally(() => commit("SET_LOADING", false))
        })
    },

    foodhubcreate({ commit }, payload) {
        commit("SET_LOADING", true)
        return new Promise((resolve, reject) => {
            foodhub.addFoodHub(payload)
                .then(res => {
                    resolve(res)
                })
                .catch((err) => {
                    commit("SET_ERROR", err);
                    reject(err);
                })
                .finally(() => commit("SET_LOADING", false))
        })
    },

    foodhubupdate({ commit }, payload) {
        commit("SET_LOADING", true)
        return new Promise((resolve, reject) => {
            foodhub.updateFoodHub(payload)
                .then(res => {
                    resolve(res)
                })
                .catch((err) => {
                    commit("SET_ERROR", err);
                    reject(err);
                })
                .finally(() => commit("SET_LOADING", false))
        })
    },

    getPackageImages({ commit }, id) {
        commit("SET_LOADING", true)
        return new Promise((resolve, reject) => {
            foodhub.getPackageImages(id)
                .then(res => {
                    resolve(res)
                })
                .catch((err) => {
                    commit("SET_ERROR", err);
                    reject(err);
                })
                .finally(() => commit("SET_LOADING", false))
        })

    },


    verifyClientPurchase({ commit }, payload) {
        return new Promise((resolve, reject) => {

            foodhub.verifyClientPurchase(payload)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        })
    },
    SuperCatID({ commit }, id) {
        commit("SET_SUPERCATEGORYID", id)
    }
}


export const mutations = {
    SET_FOOD_HUB_SUPER_CAT(state, data) {
        state.supercategories = data
    },
    SET_FOOD_HUB_SUB_CAT(state, data) {
        state.subcategories = data
    },
    SET_ACCOUNT(state, data) {
        state.auth = data
    },
    SET_LOGIN(state, data) {
        state.login = data
    },
    SET_LOGIN_WITH_PHONE(state, data) {
        state.login = data
    },
    SET_DASHBOARD_DATA(state, data) {
        state.revenue = data
    },
    SET_TRANSACTION(state, data) {
        state.transactions = data
    },
    SET_REQUIEDDATA(state, data) {
        state.foodhub = data
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },

    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_SUPERCATEGORYID(state, id) {
        state.subcategoryid = id
    }
}

export const getters = {
    supercategories: state => state.supercategories,
    subcategories: state => state.subcategories,
    foodhub: (state) => state.foodhub,
    subcategoryid: state => state.subcategoryid
}